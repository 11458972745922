import { defineStore } from "pinia";
import { useMasters } from "~/store/masters";
import {
  sameYear,
  getWeek,
  getStartOfWeek,
  getEndOfWeek,
  setWeekDays,
  addDays,
  changeFormatMDY,
  normalizeDate,
} from "~/composables/dates";

export const useCalendar = defineStore("calendar", {
  state: () => ({
    // currentDate: new Date().getDay() + (6 % 7),
    // selectedDate: new Date("08/23/2021"),
    // numberWeek: getWeek(new Date("08/23/2021")),
    // currentWeekStartDate: getStartOfWeek(new Date("08/23/2021")),
    // currentWeekEndDate: getEndOfWeek(new Date("08/23/2021")),
    // weekDays: setWeekDays(getStartOfWeek(new Date("08/23/2021"))),

    selectedDate: new Date(),
    numberWeek: getWeek(new Date()),
    currentWeekStartDate: getStartOfWeek(new Date()),
    currentWeekEndDate: getEndOfWeek(new Date()),
    weekDays: setWeekDays(getStartOfWeek(new Date())),
    weekDaysRange: setWeekDays(getStartOfWeek(new Date())).map(
      (day) => day.date
    ),
    weekDaysRangeOnlyDates: setWeekDays(getStartOfWeek(new Date())).map((day) =>
      getDateDMY(day.date, "-")
    ),
    holidaysWeek: [],
  }),
  actions: {
    // setCurrentDate(date) {
    //   this.currentDate = date;
    // },
    setSelectedDate(date) {
      this.selectedDate = date;
    },
    setNumberWeek() {
      this.numberWeek = getWeek(this.currentWeekStartDate);
    },
    setCurrentWeekStartDate(date) {
      this.currentWeekStartDate = date;
    },
    setHolidaysWeek() {
      this.holidaysWeek = [];
      const masters = useMasters();
      const holidaysPerWeek = masters.getHolidaysPerWeek;
      const holidays = holidaysPerWeek[this.numberWeek];

      if (holidays) {
        for (const holiday of holidays) {
          if (sameYear(changeFormatMDY(holiday.FESTIVO), this.selectedDate)) {
            this.holidaysWeek.push(holiday);
          }
        }
      }
    },
    goToPreviousWeek() {
      this.currentWeekStartDate = new Date(
        addDays(this.currentWeekStartDate, -7)
      );
      this.currentWeekEndDate = new Date(addDays(this.currentWeekEndDate, -7));
      this.updateWeek();
    },
    goToNextWeek() {
      this.currentWeekStartDate = new Date(
        addDays(this.currentWeekStartDate, 7)
      );
      this.currentWeekEndDate = new Date(addDays(this.currentWeekEndDate, 7));
      this.updateWeek();
    },
    goToSelectedWeek() {
      this.selectedDate = this.weekDaysRange[this.weekDaysRange.length - 1];
      this.currentWeekStartDate = getStartOfWeek(this.selectedDate);
      this.currentWeekEndDate = getEndOfWeek(this.selectedDate);
      this.weekDays = setWeekDays(this.currentWeekStartDate);
      this.weekDaysRange = this.getOnlyWeekDays;
      this.weekDaysRangeOnlyDates = this.getOnlyWeekDaysRangeOnlyDates;
      this.setNumberWeek();
      this.setHolidaysWeek();
    },
    updateWeek() {
      this.selectedDate = this.currentWeekStartDate;
      this.weekDays = setWeekDays(this.currentWeekStartDate);
      this.weekDaysRange = this.getOnlyWeekDays;
      this.weekDaysRangeOnlyDates = this.getOnlyWeekDaysRangeOnlyDates;
      this.setNumberWeek();
      this.setHolidaysWeek();
    },
    isInWeek(date) {
      const dateFormat = getDateDMY(date, "-");
      return this.weekDaysRangeOnlyDates.includes(dateFormat);
    },
    updateWeekDays() {
      this.weekDays = setWeekDays(this.currentWeekStartDate);
      this.weekDaysRange = this.weekDays.map((day) => day.date);
      this.weekDaysRangeOnlyDates = this.weekDays.map((day) =>
        getDateDMY(day.date, "-")
      );
      this.setNumberWeek();
      this.setHolidaysWeek();
    },
    setCalendarToday() {
      const today = new Date();
      this.selectedDate = today;
      this.numberWeek = getWeek(today);
      this.currentWeekStartDate = getStartOfWeek(today);
      this.currentWeekEndDate = getEndOfWeek(today);
      this.weekDays = setWeekDays(getStartOfWeek(today));
      this.weekDaysRange = setWeekDays(getStartOfWeek(today)).map(
        (day) => day.date
      );
      this.weekDaysRangeOnlyDates = setWeekDays(getStartOfWeek(today)).map(
        (day) => getDateDMY(day.date, "-")
      );
    },
  },
  getters: {
    getWeekDays: (state) => {
      return state.weekDays;
    },
    getOnlyWeekDays: (state) => {
      return state.weekDays.map((day) => day.date);
    },
    getOnlyWeekDaysRangeOnlyDates: (state) => {
      return state.weekDays.map((day) => getDateDMY(day.date, "-"));
    },
    getNumberWeek: (state) => {
      return state.numberWeek;
    },
    getHolidaysWeek: (state) => {
      return state.holidaysWeek;
    },
    isPreviousCurrentWeek: (state) => {
      const monday = getStartOfWeek(new Date());
      return (
        normalizeDate(new Date(state.selectedDate)) <
        normalizeDate(new Date(monday))
      );
    },
  },
});
