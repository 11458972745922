import { defineStore } from "pinia";
export const useUserStore = defineStore("userdata", {
  state: () => ({
    user: {}, //MFA
    login: {}, //Login backend
    profile: {}, //Perfil Maximo
    loading: false,
    error: null,
  }),
  actions: {
    setUser(value) {
      this.user = value;
      localStorage.setItem("user", JSON.stringify(value));
    },
    setLoading(value) {
      this.loading = value;
    },
    setError(value) {
      this.error = value;
    },
    setLogin(value) {
      // DELETE: Cambiar rol
      // value.Modulos.forEach((modulo) => {
      //   if (modulo.CodigoModulo === MSCmodule) {
      //     modulo.CodigoRol = roles["admin"]; // Asignamos el nuevo CodigoRol
      //   }
      // });

      this.login = value;
      localStorage.setItem("login", JSON.stringify(value));
    },
    setProfile(value) {
      // DELETE: Cambiar subcontrata
      // value.SUBCONTRACTOR = "90";

      this.profile = value;
      localStorage.setItem("profile", JSON.stringify(value));
    },
    cleanError() {
      this.error = null;
    },
    cleanUser() {
      this.user = {};
      localStorage.removeItem("user");
    },
    cleanLogin() {
      this.login = {};
      localStorage.removeItem("login");
    },
    cleanProfile() {
      this.profile = {};
      localStorage.removeItem("profile");
    },
    resetLogin() {
      this.cleanError();
      this.cleanUser();
      this.cleanLogin();
      this.cleanProfile();
    },
  },
  getters: {
    getUser: (state) => state.user,
    getLogin: (state) => state.login,
    getProfile: (state) => state.profile,
    getError: (state) => state.error,
    getLoading: (state) => state.loading,
    getUserRole: (state) => {
      return state?.login?.Modulos?.find(
        (module) => module.CodigoModulo === MSCmodule
      )?.CodigoRol;
    },
    isRegularSubcontractor: (state) => {
      return state.getUserRole === roles["regularSubcontractor"];
    },
    isTechnician: (state) => {
      return state.getUserRole === roles["technician"];
    },
    isSquadChief: (state) => {
      return state.getUserRole === roles["squadChief"];
    },
    getSubcontractor: (state) => {
      return state?.profile?.SUBCONTRACTOR;
    },
  },
});
