export const ORGANIZATIONS = {
  WIND: {
    ORGDESC_ES: "Eólica",
    ORGDESC_EN: "Wind",
  },
  BIOM: {
    ORGDESC_ES: "Biomasa",
    ORGDESC_EN: "Biomass",
  },
  HID: {
    ORGDESC_ES: "Hidráulica",
    ORGDESC_EN: "Hydraulics",
  },
  PV: {
    ORGDESC_ES: "Fotovoltaica",
    ORGDESC_EN: "Photovoltaic",
  },
  TERM: {
    ORGDESC_ES: "Termosolar",
    ORGDESC_EN: "Thermal",
  },
  BESS: {
    ORGDESC_ES: "Almacenamiento",
    ORGDESC_EN: "Battery Energy Storage System",
  },
  GD: {
    ORGDESC_ES: "Generación distribuida",
    ORGDESC_EN: "Distributed generation",
  },
  MET: {
    ORGDESC_ES: "Meteo",
    ORGDESC_EN: "Meteo",
  },
};
