import { default as crewokmNnx42ClMeta } from "/opt/app-root/src/pages/crew.vue?macro=true";
import { default as healthcheckSSy3nT9ociMeta } from "/opt/app-root/src/pages/healthcheck.vue?macro=true";
import { default as indexIdyGYUy7AoMeta } from "/opt/app-root/src/pages/index.vue?macro=true";
import { default as loginED1kRNhm2UMeta } from "/opt/app-root/src/pages/login.vue?macro=true";
import { default as saturationdPg4wklNcmMeta } from "/opt/app-root/src/pages/saturation.vue?macro=true";
import { default as scheduling53sLTRjqS3Meta } from "/opt/app-root/src/pages/scheduling.vue?macro=true";
export default [
  {
    name: "crew___en",
    path: "/en/crew",
    meta: crewokmNnx42ClMeta || {},
    component: () => import("/opt/app-root/src/pages/crew.vue")
  },
  {
    name: "crew___es",
    path: "/crew",
    meta: crewokmNnx42ClMeta || {},
    component: () => import("/opt/app-root/src/pages/crew.vue")
  },
  {
    name: "healthcheck___en",
    path: "/en/healthcheck",
    component: () => import("/opt/app-root/src/pages/healthcheck.vue")
  },
  {
    name: "healthcheck___es",
    path: "/healthcheck",
    component: () => import("/opt/app-root/src/pages/healthcheck.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexIdyGYUy7AoMeta || {},
    component: () => import("/opt/app-root/src/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/",
    meta: indexIdyGYUy7AoMeta || {},
    component: () => import("/opt/app-root/src/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginED1kRNhm2UMeta || {},
    component: () => import("/opt/app-root/src/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: loginED1kRNhm2UMeta || {},
    component: () => import("/opt/app-root/src/pages/login.vue")
  },
  {
    name: "saturation___en",
    path: "/en/saturation",
    meta: saturationdPg4wklNcmMeta || {},
    component: () => import("/opt/app-root/src/pages/saturation.vue")
  },
  {
    name: "saturation___es",
    path: "/saturation",
    meta: saturationdPg4wklNcmMeta || {},
    component: () => import("/opt/app-root/src/pages/saturation.vue")
  },
  {
    name: "scheduling___en",
    path: "/en/scheduling",
    meta: scheduling53sLTRjqS3Meta || {},
    component: () => import("/opt/app-root/src/pages/scheduling.vue")
  },
  {
    name: "scheduling___es",
    path: "/scheduling",
    meta: scheduling53sLTRjqS3Meta || {},
    component: () => import("/opt/app-root/src/pages/scheduling.vue")
  }
]