import { useUserStore } from "~/store/auth";

export const MSCmodule = "4";

export const roles = {
  assetChief: "1", // Jefe de parque
  squadChief: "2", //Jefe de Brigada
  technician: "3", //Técnico
  GMAOSupport: "4", //Soporte GMAO (Técnico)
  queries: "5", //Consultas (Técnico)
  admin: "6", //Adminsitrador
  regularSubcontractor: "7", //Subcontrata no habitual
};

export const hasRole = (requiredRole) => {
  const userStore = useUserStore();
  return userStore.getUserRole === roles[requiredRole];
};
