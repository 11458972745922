import { defineStore } from "pinia";

export const useGlobalCrews = defineStore("globalCrews", {
  state: () => ({
    dragging: false,
    crewData: {},
    layout: [],
    dropLayout: null,
    dropLayoutChildren: null,
    crewsDayLaborcode: {},
    filteredCrewsDayLaborcode: {},
    colorCrewMap: {},
    filters: {
      name: "",
      day: "",
      description: "",
      technicians: "",
      type: [],
      subcontractor: [],
    },
    crewsListado: [],
  }),
  actions: {
    setIsDragging(value) {
      this.dragging = value;
    },
    setCrewData(value) {
      this.crewData = value;
    },
    clearCrewData() {
      this.crewData = {};
    },
    pushCrewLayout(value) {
      this.layout.push(value);
    },
    clearLayout() {
      this.layout = [];
    },
    setDropLayout(value) {
      this.dropLayout = value;
    },
    setDropLayoutChildren(value) {
      this.dropLayoutChildren = value;
    },

    setCrewsDayLaborcode(value) {
      this.crewsDayLaborcode = value;
      this.filteredCrewsDayLaborcode = value;
    },
    setFilteredCrewsDayLaborcode(crews) {
      if (!crews || !crews.length) {
        this.filteredCrewsDayLaborcode = this.crewsDayLaborcode;
      }
      const crewIds = crews.map((crew) => crew.AMCREWID);
      this.filteredCrewsDayLaborcode = Object.fromEntries(
        Object.entries(this.crewsDayLaborcode).filter(([key, value]) =>
          crewIds.includes(key)
        )
      );
    },
  },
  getters: {},
});
