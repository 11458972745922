import { defineStore } from "pinia";
import WWSS from "~/utils/wwss";
import { useWarningStore } from "./globalMessage";

import { useMasters } from "~/store/masters";

import {
  parseCalendarWOToCrews,
  parseAlgorithmWOToCrews,
  parsePlanningOTs,
} from "~/composables/workorders";

import { countAppliedFilters } from "~/composables/general";
import { it } from "vuetify/locale";

export const useGlobalScheduling = defineStore("globalScheduling", {
  state: () => ({
    planningWorkorders: [],
    filteredPlanningWorkorders: [],
    algorithmWorkorders: [],
    filteredAlgorithmWorkorders: [],
    delayedPlanningWorkorders: [],
    filteredDelayedPlanningWorkorders: {},
    areDelayedWorkordersUpdated: false,
    crewsSlots: {},
    algorithmLastExecution: null,
    filters: {
      // Comunes
      wo_number: "",
      wo_description: "",
      worktype: [],
      crew: [],
      group: [],
      site: [],
      asset: [],
      location: [],
      subcontractor: [],
      technicians: [],
      gama: [],
      regular: "",
      // Planificado
      wo_mother_number: "",
      technology: [],
      location_type: [],
      reprogrammable: false,
      qse: false,
      loading: {}, //para comprobar en cambio de semana
      // Datos filtrables obtenidos desde el listado de cuadrillas
      data: {
        location_type: {},
        gama: {},
        // Regular: están invertidos. FDoble negación FLG_NOT_REGULAR
        regular: [
          { title: "common.buttons.none", value: "" },
          { title: "common.buttons.yes", value: "0" },
          { title: "common.buttons.no", value: "1" },
        ],
      },
      isFiltered: false,
    },
  }),
  actions: {
    setGamaFilterData(gama_plan) {
      if (!gama_plan.CODE) return;

      const storedGama = this.filters.data.gama[gama_plan.CODE] || {};

      // Actualiza solo las descripciones que existan en el nuevo dato y estén vacías en el almacenamiento
      this.filters.data.gama[gama_plan.CODE] = {
        DESCRIPTION_ES:
          storedGama.DESCRIPTION_ES || gama_plan.DESCRIPTION_ES || "",
        DESCRIPTION_EN:
          storedGama.DESCRIPTION_EN || gama_plan.DESCRIPTION_EN || "",
      };
    },
    setLocationTypeFilterData(location_type) {
      const masters = useMasters();

      if (location_type.CODE == null) {
        return;
      }
      const storedLocation =
        this.filters.data.location_type[location_type.CODE];

      const location_type_data = masters.locTypes.list.find(
        (lt) => lt.VALUE === location_type.CODE
      );

      if (location_type_data && !storedLocation) {
        this.filters.data.location_type[location_type.CODE] = {
          DESCRIPTION_ES: location_type_data.DESCRIPTION_ES || "",
          DESCRIPTION_EN: location_type_data.DESCRIPTION_EN || "",
        };
      } else {
        if (
          !storedLocation.DESCRIPTION_ES &&
          location_type_data.DESCRIPTION_ES
        ) {
          storedLocation.DESCRIPTION_ES = location_type_data.DESCRIPTION_ES;
        }
        if (
          !storedLocation.DESCRIPTION_EN &&
          location_type_data.DESCRIPTION_EN
        ) {
          storedLocation.DESCRIPTION_EN = location_type_data.DESCRIPTION_EN;
        }
      }
    },
    setCrewsSlots(value) {
      this.crewsSlots = value;
    },
    setPlanningWorkorders(value) {
      this.planningWorkorders = value;
    },
    setAlgorithmWorkorders(value) {
      this.algorithmWorkorders = value;
    },
    async setDelayedPlanningWorkorders(value) {
      this.delayedPlanningWorkorders = value;
      await this.setFilteredDelayedPlanningWorkorders();
      this.areDelayedWorkordersUpdated = true;
    },
    setFilteredPlanningWorkorders() {
      const masters = useMasters();

      const workorders_filtered = this.planningWorkorders.filter((wo) => {
        const crew = masters.crews.list.find((crew) => {
          return crew.AMCREW == wo.AMCREW;
        });

        // Comunes
        const woNumberMatch =
          this.filters.wo_number === "" ||
          this.filters.wo_number === null ||
          wo.WONUM.toLowerCase().includes(this.filters.wo_number.toLowerCase());

        const woDescriptionMatch =
          this.filters.wo_description === "" ||
          this.filters.wo_description === null ||
          removeDiacritics(wo.DESCRIPTION.toLowerCase()).includes(
            removeDiacritics(this.filters.wo_description.toLowerCase())
          );

        const worktypeMatch =
          this.filters.worktype.length === 0 ||
          this.filters.worktype.length === null ||
          (this.filters.worktype.length > 0 &&
            this.filters.worktype.includes(wo.CLASE_TRABAJO));

        const groupMatch =
          this.filters.group.length === 0 ||
          this.filters.group.length === null ||
          (this.filters.group.length > 0 &&
            this.filters.group.includes(wo.PERSONGROUP));

        const crewMatch =
          this.filters.crew.length === 0 ||
          this.filters.crew.length === null ||
          (this.filters.crew.length > 0 &&
            this.filters.crew.includes(wo.AMCREW));

        const siteMatch =
          this.filters.site.length === 0 ||
          this.filters.site.length === null ||
          (this.filters.site.length > 0 &&
            this.filters.site.includes(wo.SITEID));

        const asset = wo.LOCATION.split(".")[0];
        const assetMatch =
          this.filters.asset.length === 0 ||
          this.filters.asset.length === null ||
          (this.filters.asset.length > 0 && this.filters.asset.includes(asset));

        const locationMatch =
          this.filters.location.length === 0 ||
          this.filters.location.length === null ||
          (this.filters.location.length > 0 &&
            this.filters.location.includes(wo.LOCATION));

        const techniciansMatch =
          this.filters.technicians.length === 0 ||
          this.filters.technicians.length === null ||
          (this.filters.technicians.length > 0 &&
            searchInString(wo.PLANLABOR, this.filters.technicians));

        const subcontractorMatch =
          this.filters.subcontractor.length === 0 ||
          this.filters.subcontractor.length === null ||
          (this.filters.subcontractor.length > 0 &&
            crew &&
            this.filters.subcontractor.includes(crew.CREWWORKGROUPAE));

        const gamaMatch =
          this.filters.gama.length === 0 ||
          this.filters.gama.length === null ||
          (this.filters.gama.length > 0 &&
            this.filters.gama.includes(wo.GAMA_PLAN));

        const regularMatch =
          this.filters.regular === "" ||
          this.filters.regular.includes(wo.FLG_NOTREGULAR);

        // Planificado

        const technologyMatch =
          this.filters.technology.length === 0 ||
          this.filters.technology.length === null ||
          (this.filters.technology.length > 0 &&
            this.filters.technology.includes(wo.TECNOLOGIA));

        const locTypeMatch =
          this.filters.location_type.length === 0 ||
          this.filters.location_type.length === null ||
          (this.filters.location_type.length > 0 &&
            this.filters.location_type.includes(wo.TIPO_LOCATION));

        const woMotherNumberMatch =
          this.filters.wo_mother_number === "" ||
          this.filters.wo_mother_number === null ||
          (wo.OTMADRE != null &&
            wo.OTMADRE.toLowerCase().includes(
              this.filters.wo_mother_number.toLowerCase()
            ));

        const reprogrammableMatch =
          !this.filters.reprogrammable ||
          (this.filters.reprogrammable && wo.OTAREPROGRAMAR == "1");

        const qseMatch =
          !this.filters.qse || (this.filters.qse && wo.QSEW == "1");

        return (
          woNumberMatch &&
          woDescriptionMatch &&
          techniciansMatch &&
          subcontractorMatch &&
          worktypeMatch &&
          crewMatch &&
          siteMatch &&
          assetMatch &&
          locationMatch &&
          groupMatch &&
          gamaMatch &&
          regularMatch &&
          technologyMatch &&
          locTypeMatch &&
          woMotherNumberMatch &&
          reprogrammableMatch &&
          qseMatch
        );
      });

      if (
        workorders_filtered.length < 1 &&
        countAppliedFilters(this.filters) > 0
      ) {
        this.filteredPlanningWorkorders = [];
      } else {
        this.filteredPlanningWorkorders =
          parseCalendarWOToCrews(workorders_filtered);
      }
    },
    setFilteredPlanningCrew(crew) {
      const crew_data = this.filteredPlanningWorkorders.find((c) => {
        return c.id === crew.AMCREW;
      });
      const slot = crew_data.slots.find((s) => {
        return s.AMCREWAVAILAEID === crew.AMCREWAVAILAEID;
      });
      slot.LABORCODE_AMCREWLABORAEID = crew.LABORCODE_AMCREWLABORAEID;
      slot.workers =
        crew.LABORCODE_AMCREWLABORAEID == "" ||
        crew.LABORCODE_AMCREWLABORAEID == null
          ? []
          : crew.LABORCODE_AMCREWLABORAEID.split(",").map((worker) =>
              worker.replace(/_.*/, "")
            );
      crew_data.ots = [];

      //OTS
      const crew_ots = this.planningWorkorders.filter((wo) => {
        return wo.AMCREW == crew.AMCREW;
      });
      const { wo, unplanned_wo } = parsePlanningOTs(crew_ots, crew_data);
      crew_data.ots = wo;
      crew_data.unplanned_ots = unplanned_wo;
    },
    setFilteredAlgorithmWorkorders() {
      function getAsset(text) {
        const regex = /-(\d+)\./;
        const match = text.match(regex);
        return match ? match[1] : null;
      }

      if (!this.algorithmWorkorders || this.algorithmWorkorders.length == 0) {
        this.filteredAlgorithmWorkorders = [];
        return;
      }
      const masters = useMasters();

      const workorders_filtered = this.algorithmWorkorders.filter((wo) => {
        const crew = masters.crews.list.find((crew) => {
          return crew.AMCREWID == wo.OPT_CREW;
        });
        const ot = wo.COD_WONUM_ID.split("-")[1];
        const siteid = wo.COD_LOCATION_ID.split("-")[0];

        // Comunes
        const woNumberMatch =
          this.filters.wo_number === "" ||
          this.filters.wo_number === null ||
          ot.toLowerCase().includes(this.filters.wo_number.toLowerCase());

        const woDescriptionMatch =
          this.filters.wo_description === "" ||
          this.filters.wo_description === null ||
          removeDiacritics(wo.DES_DESCRIPTION.toLowerCase()).includes(
            removeDiacritics(this.filters.wo_description.toLowerCase())
          );

        const worktypeMatch =
          this.filters.worktype.length === 0 ||
          this.filters.worktype.length === null ||
          (this.filters.worktype.length > 0 &&
            this.filters.worktype.includes(wo.COD_WORKTYPE));

        const groupMatch =
          this.filters.group.length === 0 ||
          this.filters.group.length === null ||
          (this.filters.group.length > 0 &&
            this.filters.group.includes(wo.COD_PERSONGROUP));

        const crewMatch =
          this.filters.crew.length === 0 ||
          this.filters.crew.length === null ||
          (this.filters.crew.length > 0 &&
            crew &&
            this.filters.crew.includes(crew.AMCREW));

        const siteMatch =
          this.filters.site.length === 0 ||
          this.filters.site.length === null ||
          (this.filters.site.length > 0 && this.filters.site.includes(siteid));

        const subcontractorMatch =
          this.filters.subcontractor.length === 0 ||
          this.filters.subcontractor.length === null ||
          (this.filters.subcontractor.length > 0 &&
            this.filters.subcontractor.includes(wo.COD_SUBCONTRACTOR));

        const assetMatch =
          this.filters.asset.length === 0 ||
          this.filters.asset.length === null ||
          (this.filters.asset.length > 0 &&
            this.filters.asset.includes(getAsset(wo.COD_LOCATION_ID)));

        const locationMatch =
          this.filters.location.length === 0 ||
          this.filters.location.length === null ||
          (this.filters.location.length > 0 &&
            this.filters.location.includes(wo.COD_LOCATION_ID.split("-")[1]));

        const techniciansMatch =
          this.filters.technicians.length === 0 ||
          this.filters.technicians.length === null ||
          (this.filters.technicians.length > 0 &&
            searchInString(wo.OPT_TRABAJADORES, this.filters.technicians));

        const gamaMatch =
          this.filters.gama.length === 0 ||
          this.filters.gama.length === null ||
          (this.filters.gama.length > 0 &&
            this.filters.gama.includes(wo.COD_GAMA));

        const wo_regular = wo.FLG_NOTREGULAR ? "1" : "0";
        const regularMatch =
          this.filters.regular === "" ||
          this.filters.regular.includes(wo_regular);

        return (
          woNumberMatch &&
          woDescriptionMatch &&
          subcontractorMatch &&
          worktypeMatch &&
          crewMatch &&
          siteMatch &&
          assetMatch &&
          locationMatch &&
          techniciansMatch &&
          groupMatch &&
          gamaMatch &&
          regularMatch
        );
      });

      this.filteredAlgorithmWorkorders =
        parseAlgorithmWOToCrews(workorders_filtered);
    },
    async setFilteredDelayedPlanningWorkorders() {
      const nuxtApp = useNuxtApp();
      // const masters = useMasters();

      // const delayed_workorders_filtered = this.delayedPlanningWorkorders.filter(
      //   (wo) => {
      //     const crew = masters.crews.list.find((crew) => {
      //       return crew.AMCREW == wo.AMCREW;
      //     });

      //     // Comunes
      //     const woNumberMatch =
      //       this.filters.wo_number === "" ||
      //       this.filters.wo_number === null ||
      //       wo.WONUM.toLowerCase().includes(
      //         this.filters.wo_number.toLowerCase()
      //       );

      //     const woDescriptionMatch =
      //       this.filters.wo_description === "" ||
      //       this.filters.wo_description === null ||
      //       wo.DESCRIPTION.toLowerCase().includes(
      //         this.filters.wo_description.toLowerCase()
      //       );

      //     const worktypeMatch =
      //       this.filters.worktype.length === 0 ||
      //       this.filters.worktype.length === null ||
      //       (this.filters.worktype.length > 0 &&
      //         this.filters.worktype.includes(wo.CLASE_TRABAJO));

      //     const groupMatch =
      //       this.filters.group.length === 0 ||
      //       this.filters.group.length === null ||
      //       (this.filters.group.length > 0 &&
      //         this.filters.group.includes(wo.PERSONGROUP));

      //     const crewMatch =
      //       this.filters.crew.length === 0 ||
      //       this.filters.crew.length === null ||
      //       (this.filters.crew.length > 0 &&
      //         this.filters.crew.includes(wo.AMCREW));

      //     const siteMatch =
      //       this.filters.site.length === 0 ||
      //       this.filters.site.length === null ||
      //       (this.filters.site.length > 0 &&
      //         this.filters.site.includes(wo.SITEID));

      //     const asset = wo.LOCATION.split(".")[0];
      //     const assetMatch =
      //       this.filters.asset.length === 0 ||
      //       this.filters.asset.length === null ||
      //       (this.filters.asset.length > 0 &&
      //         this.filters.asset.includes(asset));

      //     const locationMatch =
      //       this.filters.location.length === 0 ||
      //       this.filters.location.length === null ||
      //       (this.filters.location.length > 0 &&
      //         this.filters.location.includes(wo.LOCATION));

      //     const subcontractorMatch =
      //       this.filters.subcontractor.length === 0 ||
      //       this.filters.subcontractor.length === null ||
      //       (this.filters.subcontractor.length > 0 &&
      //         crew &&
      //         this.filters.subcontractor.includes(crew.CREWWORKGROUPAE));

      //     // Planificado

      //     const technologyMatch =
      //       this.filters.technology.length === 0 ||
      //       this.filters.technology.length === null ||
      //       (this.filters.technology.length > 0 &&
      //         this.filters.technology.includes(wo.TECNOLOGIA));

      //     const locTypeMatch =
      //       this.filters.location_type.length === 0 ||
      //       this.filters.location_type.length === null ||
      //       (this.filters.location_type.length > 0 &&
      //         this.filters.location_type.includes(wo.TIPO_LOCATION));

      //     const woMotherNumberMatch =
      //       this.filters.wo_mother_number === "" ||
      //       this.filters.wo_mother_number === null ||
      //       (wo.OTMADRE != null &&
      //         wo.OTMADRE.toLowerCase().includes(
      //           this.filters.wo_mother_number.toLowerCase()
      //         ));

      //     const reprogrammableMatch =
      //       !this.filters.reprogrammable ||
      //       (this.filters.reprogrammable && wo.OTAREPROGRAMAR == "1");

      //     const qseMatch =
      //       !this.filters.qse || (this.filters.qse && wo.QSE == "1");

      //     return (
      //       woNumberMatch &&
      //       woDescriptionMatch &&
      //       subcontractorMatch &&
      //       worktypeMatch &&
      //       crewMatch &&
      //       siteMatch &&
      //       assetMatch &&
      //       locationMatch &&
      //       groupMatch &&
      //       technologyMatch &&
      //       locTypeMatch &&
      //       woMotherNumberMatch &&
      //       reprogrammableMatch &&
      //       qseMatch
      //     );
      //   }
      // );

      // if (
      //   !delayed_workorders_filtered.length &&
      //   countAppliedFilters(this.filters) > 0
      // ) {
      //   this.filteredDelayedPlanningWorkorders = {};
      // } else {
      //   const grouped_delayed_workorders = delayed_workorders_filtered.reduce(
      //     (acc, workorder) => {
      //       const crew =
      //         workorder.AMCREW || nuxtApp.$i18n.t("scheduling.withoutCrew");
      //       if (!acc[crew]) {
      //         acc[crew] = [];
      //       }
      //       acc[crew].push(workorder);
      //       return acc;
      //     },
      //     {}
      //   );

      //   this.filteredDelayedPlanningWorkorders = grouped_delayed_workorders;
      // }

      const grouped_delayed_workorders = this.delayedPlanningWorkorders.reduce(
        (acc, workorder) => {
          const crew =
            workorder.AMCREW || nuxtApp.$i18n.t("scheduling.withoutCrew");
          if (!acc[crew]) {
            acc[crew] = [];
          }
          acc[crew].push(workorder);
          return acc;
        },
        {}
      );

      this.filteredDelayedPlanningWorkorders = grouped_delayed_workorders;
    },
    async saveCurrentFilters() {
      if (this.filters.gama.length) {
        this.filters.loading.gama = [...this.filters.gama];
      }

      if (this.filters.location_type.length) {
        this.filters.loading.location_type = [...this.filters.location_type];
      }

      await this.clearFiltersData();
    },
    async clearFiltersData() {
      this.filters.data.gama = {};
      this.filters.gama = [];
      this.filters.data.location_type = {};
      this.filters.location_type = [];
    },

    updateGamaFilterData(ot, key) {
      if (!key || !ot[key]) return;

      let gamaPlan = {
        CODE: ot[key],
        DESCRIPTION_ES:
          (key === "GAMA_PLAN" && ot.GAMA_PLAN_DESCRIPTION_ES) || null,
        DESCRIPTION_EN:
          (key === "GAMA_PLAN" && ot.GAMA_PLAN_DESCRIPTION_EN) || null,
      };

      this.setGamaFilterData(gamaPlan);
    },

    updateLocationTypeFilterData(ot) {
      const locationType = {
        CODE: ot.TIPO_LOCATION,
        DESCRIPTION_ES: null,
        DESCRIPTION_EN: null,
      };
      this.setLocationTypeFilterData(locationType);
    },

    /** Añade el filtro si se encuentra entre los filtros alimentados por OTS
     * @param {String} filterKey // clave del filtro
     * @param {Array}  filteredItems //Valores filtrados anteriormente
     */
    updateFilters(filterKey, filteredItems) {
      filteredItems.forEach((item) => {
        if (
          this.filters.data[filterKey][item] &&
          !this.filters[filterKey].includes(item)
        ) {
          this.filters[filterKey].push(item);
        }
      });
    },

    async setWorkordersFilters() {
      // Recorrer las OTs para actualizar los filtros alimentados por OTs
      this.planningWorkorders.forEach((ot) => {
        this.updateGamaFilterData(ot, "GAMA_PLAN");
        this.updateLocationTypeFilterData(ot);
      });

      this.algorithmWorkorders.forEach((ot) => {
        this.updateGamaFilterData(ot, "COD_GAMA");
      });

      // Volver a seleccionar los filtros seleccionados antes de la actualziación
      const gama_filtered = this.filters.loading.gama ?? [];
      const location_type_filtered = this.filters.loading.location_type ?? [];
      this.updateFilters("gama", gama_filtered);
      this.updateFilters("location_type", location_type_filtered);

      // this.filters.loading = {};
    },
    async cleanWorkorders() {
      // Limpiar OTs
      this.algorithmWorkorders = [];
      this.planningWorkorders = [];
      // Limpiado  OTs filtradas
      this.filteredAlgorithmWorkorders = [];
      this.filteredPlanningWorkorders = [];
    },
  },
  getters: {},
});
