import { useWarningStore } from "~/store/globalMessage";
import { useMasters } from "~/store/masters";
import { useGlobalScheduling } from "~/store/globalScheduling";
import WWSS from "~/utils/wwss";

export const fetchPostData = async (url, body) => {
  const config = useRuntimeConfig();
  try {
    const data = await $fetch(`api${url}`, {
      method: "POST",
      body,
      headers: {
        "API-VERSION": config.public.headers.apiVersion,
        "Content-Type": "application/json",
        "API-TOKEN": config.public.headers.apiToken,
        "API-PRODUCT": config.public.headers.apiProduct,
      },
    });

    return data;
  } catch (error) {
    console.log("Error en la autenticaciónd de backend (POST)", error);
    return {
      error: {
        code: "backend.default",
      },
    };
  }
};

export const fetchGetData = async (url, params) => {
  const config = useRuntimeConfig();

  try {
    const data = await $fetch(`api${url}`, {
      method: "POST",
      params,
      headers: {
        "API-VERSION": config.public.headers.apiVersion,
        "Content-Type": "application/json",
        "API-TOKEN": config.public.headers.apiToken,
        "API-PRODUCT": config.public.headers.apiProduct,
      },
    });

    return data;
  } catch (error) {
    // Manejar errores de la solicitud
    console.error("Error en la autenticación del backend", error);
    return {
      error: {
        code: "maximo.default",
      },
    };
  }
};
export async function startStatement(endpoint, params) {
  return await fetchPostData(endpoint, params);
}

// Función para obtener los datos una vez el trabajo ha finalizado
export async function getStatementPending(statement_id) {
  const response = await fetchPostData(WWSS.databricks.get_statement_pending, {
    statement_id,
  });
  return response;
}

export const fetchDatabricksWithRetry = async (
  endpoint,
  params,
  retries = 5,
  delay = 2000
) => {
  const checkJobStatus = async (job_result, retriesLeft) => {
    for (let i = 0; i < retriesLeft; i++) {
      if (
        job_result.status.state === "PENDING" ||
        job_result.status.state === "RUNNING"
      ) {
        job_result = await getStatementPending(job_result.statement_id);
      } else {
        return job_result;
      }

      // Espera antes de volver a intentar
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
    // Si se agotan los reintentos, retornar el último resultado obtenido
    return job_result;
  };

  try {
    // Inicia el trabajo y obtiene el jobId
    let job_result = await startStatement(endpoint, params);

    // Verificar el estado del trabajo hasta que tenga éxito o se agoten los reintentos
    job_result = await checkJobStatus(job_result, retries);

    // Devolver el resultado final obtenido
    return job_result;
  } catch (error) {
    console.error("Error in fetchDataWithRetry:", error);
    throw error;
  }
};

/**
 * Función gestión de databricks
 * @returns
 */

export const useDatabricksService = () => {
  const requestState = ref({
    status: "idle",
    data: null,
    error: null,
  });

  const makeRequest = async (url, params) => {
    requestState.status = "pending";

    try {
      const response = await $fetch(`api${url}`, {
        method: "GET",
        params,
        headers: {
          "API-VERSION": config.public.headers.apiVersion,
          "Content-Type": "application/json",
          "API-TOKEN": config.public.headers.apiToken,
          "API-PRODUCT": config.public.headers.apiProduct,
        },
        timeout: 10000,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      requestState.status = "success";
      requestState.data = data;
      requestState.error = null;
    } catch (error) {
      requestState.status = "error";
      requestState.error = error.message;
      console.error("Error al realizar la solicitud:", error);
    }
  };

  return {
    requestState,
    makeRequest,
  };
};

/**
 * Devuelve un array ordenado de manera ascendente por una se las propiedades de los objetos
 * @param {Array} array - Array de objetos a ordenar
 * @param {String} property - Nombre de la propiedad por la cual ordenar
 * @param {String} order - Orden de clasificación: 'asc' para ascendente o 'desc' para descendente
 * @returns {Array} - Array ordenado
 */
export const sortElements = (array, property, order = "asc") => {
  return array.sort((a, b) => {
    const propertyA = (a[property] || "").toUpperCase();
    const propertyB = (b[property] || "").toUpperCase();

    let comparison = 0;
    if (propertyA > propertyB) {
      comparison = 1;
    } else if (propertyA < propertyB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  });
};

export const generateMessageType = (respuestas, successfulMsg) => {
  const msgErrores = [];
  const msgCorrectas = [];
  const correctas = [];

  const globalMessage = useWarningStore();

  // generar respuesta con errores y con correctas
  for (const res in respuestas) {
    if (respuestas[res].error) {
      msgErrores.push(`${res} :  ${respuestas[res].error.message}`);
    } else {
      correctas.push(respuestas[res]);
      successfulMsg != ""
        ? msgCorrectas.push(`${res} : ${successfulMsg} `)
        : msgCorrectas.push(`${res}`);
    }
  }

  if (msgErrores.length > 0 && msgCorrectas.length == 0) {
    globalMessage.showGenericErrorMessage(msgErrores);
  } else if (msgCorrectas.length > 0 && msgErrores.length == 0) {
    globalMessage.showGenericSuccessMessage(msgCorrectas);
  } else if (msgCorrectas.length > 0 && msgErrores.length > 0) {
    globalMessage.showGenericWarningMessage([...msgErrores, ...msgCorrectas]);
  }
  return correctas;
};

export const resizeCalendar = () => {
  // Modifico el size ya que pinta mal los item del grid y con esto hace un resize
  setTimeout(function () {
    const calendarData = document.getElementsByClassName("calendar__data")[0];
    if (calendarData.style) {
      calendarData.style.width = calendarData.offsetWidth - 1 + "px";
    }
  }, 1000);
};

export const beforeUnloadHandler = (event) => {
  event.preventDefault();
  event.returnValue = "";
};

// Función para eliminar duplicados de un array de objetos basado en una propiedad clave
export const removeDuplicates = (array, key) => {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
};

export const searchInString = (stringToCheck, arrayToCheck) => {
  if (typeof stringToCheck !== "string") {
    return false;
  }
  return arrayToCheck.some((item) =>
    stringToCheck.toLowerCase().includes(item.toLowerCase())
  );
};

export const countAppliedFilters = (filters) => {
  const globalScheduling = useGlobalScheduling();
  let count = 0;

  // Convierte los filtros en objetos array key/value para iterar
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value) && value.length > 0) {
      count++;
    } else if (typeof value === "boolean" && value) {
      count++;
    } else if (typeof value === "string" && value !== "" && value !== null) {
      count++;
    }
  }

  globalScheduling.isFiltered = count > 0;

  return count;
};

export const getMateCompleteName = (mate_id) => {
  const masters = useMasters();

  // Encuentra el objeto correspondiente al mate_id
  const mate_data = masters.mates.list.find((m) => m.LABORID === mate_id);

  if (mate_data) {
    // Construye el nombre completo
    const name = `${mate_data.FIRSTNAME} ${mate_data.LASTNAME ?? ""}`;
    const nameParts = [mate_id, mate_data.DEFAULTPERSONGROUP, name].filter(
      Boolean
    ); // Filtra valores nulos, undefined o vacíos

    return nameParts.length > 0 ? nameParts.join(" - ") : mate_id;
  }

  // Si no se encuentra el mate_data, retorna el mate_id como está
  return mate_id;
};

/**
 * Elimina las tildes de vocales apra búsquedas y filtrados
 * @param {String} str
 * @returns
 */
export const removeDiacritics = (text) => {
  return text.normalize("NFD").replace(/([aeiouAEIOU])[\u0300-\u0301]/g, "$1"); // Eliminar solo las tildes
};
