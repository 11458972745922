export default {
  login: {
    backend: "/common/login",
    profile: "/maximo/user/profile",
  },
  user: {
    machines: {
      list: "/maximo/user/machine/list",
      set: "/maximo/user/machine/set",
    },
    mates: "/maximo/user/mates",
    crews: {
      get_crew: "/maximo/crew/get_crew",
      get_all_crews_siteid: "/maximo/crew/get_all_crews_siteid",
      get_all_crews_days_laborcode: "/maximo/crew/get_all_crews_days_laborcode",
      get_crew_days_laborcode: "/maximo/crew/get_crew_days_laborcode",
      day: {
        create: "/maximo/crew/day/create",
        get: "/maximo/crew/day/get",
        delete: "/maximo/crew/day/delete",
      },
      site: {
        get: "/maximo/crew/siteid/get",
        create: "/maximo/crew/siteid/create",
        delete: "/maximo/crew/siteid/delete",
      },
      day_labor_code: {
        get: "/maximo/crew/day_laborcode/get",
        create: "/maximo/crew/day_laborcode/create",
        modify: "/maximo/crew/day_laborcode/modify",
        delete: "/maximo/crew/day_laborcode/delete",
      },
      get: "/maximo/crew/get",
      create: "/maximo/crew/create",
      modify: "/maximo/crew/modify",
      deactivate: "/maximo/crew/deactivate",
      activate: "/maximo/crew/activate",
    },
  },
  master: {
    holidays: "/maximo/master/holidays",
    subcontractors: "/maximo/master/subcontractor",
    crews: {
      types: "/maximo/master/crew_types",
    },
    personGroups: "/maximo/user/persongroups",
    workTypes: "/maximo/master/worktypes",
    locTypes: "/maximo/master/loctypes",
    technologies: "/maximo/master/tecnologies",
  },
  scheduling: {
    ots: {
      get_all_by_dates_locations:
        "/apintt/workorders/getWorkordersByDatesLocations",
    },
    workorder: {
      get_planning: "/maximo/workorder/get_planning",
      get_delayed_planning: "/maximo/workorder/get_delayed_planning",
      get_materials_planned_wo: "/maximo/workorder/get_materials_planned_wo",
      get_pendingworks_wo: "/maximo/workorder/get_pendingworks_wo",
      get_pendingworks_location: "/maximo/workorder/get_pendingworks_location",
    },
  },
  databricks: {
    statement: "/repin/databricks/statement",
    statement_pending: "/repin/databricks/statement_pending",
    get_energia_diaria: "/repin/databricks/get_energia_diaria",
    get_statement_pending: "/repin/databricks/get_statement_pending",
    get_energia_ingreso_diaria_parque:
      "/repin/databricks/get_energia_ingreso_diaria_parque",
  },
};
