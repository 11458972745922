<template>
  <v-btn variant="text" size="small" tile prepend-icon="mdi-flag-outline">
    {{ currentLanguage.toUpperCase() }}
    <v-menu activator="parent" offset-x="2" transition="slide-y-transition">
      <v-list :elevation="24">
        <v-list-item
          size="small"
          v-for="language in languages"
          :key="language.code"
          @click="changeLanguage(language.code)"
        >
          <v-list-item-title> {{ language.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>
  
  <script setup>
import { useCalendar } from "~/store/calendar";
import { useMasters } from "~/store/masters";
const calendar = useCalendar();
const nuxtApp = useNuxtApp();

const menu = ref(false);
const currentLanguage = ref("");
const languages = ref([]);

const changeLanguage = (language) => {
  const masters = useMasters();
  masters.setGlobalLoading(true);
  menu.value = false;
  currentLanguage.value = language;
  nuxtApp.$i18n.locale.value = language;
  nuxtApp.$i18n.setLocale(language);

  // Asegurarse de que la ruta sea recalculada y la clase activa se actualice

  calendar.updateWeekDays();
  setLanguages();
};

const setLanguages = () => {
  const localesArray = Object.values(nuxtApp.$i18n.locales.value);
  languages.value = localesArray
    .filter((locale) => locale.code !== currentLanguage.value)
    .map((locale) => ({
      code: locale.code,
      name: locale.name,
    }));
};

onMounted(() => {
  currentLanguage.value = nuxtApp.$i18n.locale.value;
  setLanguages();
});
</script>
  
<style scoped>
</style>
  