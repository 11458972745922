<template>
  <div class="header__wrapper">
    <v-alert
      v-if="maintenance"
      closable
      icon="mdi-alert"
      title="Mantenimiento"
      color="brand"
      :rounded="0"
      text="programado mantenimiento para el próximo 01/04/2024 entre las 10:00h y las 12:00h. Disculpe las molestias."
    ></v-alert>

    <v-progress-linear
      :model-value="masters.globalLoading ? 0 : 100"
      :indeterminate="masters.globalLoading"
      color="brand"
      :height="5"
      class="header__progress"
    ></v-progress-linear>

    <header class="header flex-wrap v-layout">
      <div clas="header__logo">
        <img :src="logoSrc" class="logo" alt="Logo MSC" />
      </div>
      <nav
        v-show="!inRoot"
        class="header__nav flex-1-1"
        :class="{ 'header__nav--disabled': masters.globalLoading }"
      >
        <client-only>
          <nuxt-link v-show="hasRole('admin')" :to="localePath('/')">
            <v-btn
              icon="mdi-home-outline"
              flat
              :readonly="masters.globalLoading"
            ></v-btn>
          </nuxt-link>
          <nuxt-link
            v-for="link in menu"
            :key="link.id"
            :to="!['saturation'].includes(link.id) ? localePath(link.path) : ''"
            active-class="nav__button--active"
            class="nav__button"
            :exact="true"
            :class="{
              'nav__button--disabled':
                masters.globalLoading || ['saturation'].includes(link.id),
            }"
          >
            {{ $t(link.title) }}
          </nuxt-link>
        </client-only>
      </nav>
      <div class="header__logout d-flex flex-row flex-wrap ga-2">
        <client-only>
          <div v-show="!inRoot">
            <v-btn
              @click="locationSelector.setVisible(true)"
              icon
              :readonly="masters.globalLoading"
              :title="$t('locationSelector.title')"
              variant="text"
            >
              <v-icon color="brand" icon="mdi-order-bool-ascending-variant">
              </v-icon>
            </v-btn>
          </div>

          <CommonLanguage class="mr-2"></CommonLanguage>

          <div class="header__data">
            <div class="header__user">
              {{ login.NombreUsuario }}
            </div>
            <div class="header__version">{{ version }}</div>
          </div>
          <v-btn
            icon="mdi-exit-to-app"
            :flat="true"
            size="small"
            class="logout"
            :title="$t('header.logOut')"
            @click="logout(user.homeAccountId)"
          >
          </v-btn>
        </client-only>
      </div>
    </header>
  </div>
</template>  

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/auth";
import { useMasters } from "~/store/masters";
import { useLocationSelector } from "~/store/locationSelector";

// Data
const maintenance = ref(false);

const nuxtApp = useNuxtApp();

const route = useRoute();

const masters = useMasters();
const userStore = useUserStore();
const locationSelector = useLocationSelector();
const { user, login } = storeToRefs(userStore);

const menuGeneral = [
  {
    id: "crew",
    path: "/crew",
    title: "crew.title",
  },
];

const menu = ref([
  {
    id: "scheduling",
    path: "/scheduling",
    title: "scheduling.title",
  },
  {
    id: "saturation",
    path: "/saturation",
    title: "saturation.title",
  },
]);

// Computadas
const version = computed(() => {
  const config = useRuntimeConfig();
  return config ? `v${config.public.version}` : "";
});

const inRoot = computed(() => {
  return route.path == "/" || route.path == "/en";
});

const logoSrc = computed(() => {
  const config = useRuntimeConfig();
  return `/img/logo/${config.public.logoImage}`;
});

// Métodos
const logout = async (accountId) => {
  await nuxtApp.$msal().signOut(accountId);
};

const handleClick = (event) => {
  if (masters.globalLoading) {
    event.preventDefault();
  }
};

onBeforeMount(() => {
  if (!hasRole("technician")) {
    menu.value = [...menu.value, ...menuGeneral];
  }
});
</script>


<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
header {
  // border-top: 5px solid red;
  padding: $space-xs;
  border-bottom: 1px solid $color-light-300;
  background-color: $color-light-100;
  gap: $space-xs;
}

.logo {
  max-width: 160px;
}

.nav__button {
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: $color-light-100;
  padding: $space-xs $space-md;
  cursor: pointer;
  border: 1px solid $color-dark-700;
  width: auto;
  border-radius: $border-radius-xs;
  color: $color-dark-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  position: relative;

  &:not(.nav__button--disabled):hover {
    background-color: $color-light-300;
    border-color: $color-light-300;
  }

  &--active {
    color: $color-light-100;
    background-color: $color-brand-500;
    border-color: $color-brand-500;

    &:not(.nav__button--disabled):hover {
      background-color: $color-brand-500;
      border-color: $color-brand-500;
    }
  }

  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.header {
  width: 100%;
  top: 5px;
  position: fixed;

  &__progress {
    position: fixed;
    top: 0;
    z-index: 2;
  }

  &__wrapper {
    position: fixed;
    width: 100%;
    z-index: 2;
    top: 0;
    height: 5px;
    background-color: $color-light-100;
  }

  &__nav {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: $space-sm;
    position: relative;

    &--disabled {
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 10;
      }
    }
  }

  &__logout {
    display: flex;
    flex-flow: row nowrap;
    gap: $space-xs;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  &__data {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
  }

  &__user {
    font-weight: bolder;
    text-align: right;
  }

  &__version {
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
  }
}

.logout {
  display: block;
}
</style>