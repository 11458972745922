import { defineStore } from "pinia";
import { reactive } from "vue";

let alertId = 0;

export const errorData = {
  type: "error",
  icon: "mdi-alert-circle",
  iconColor: "brand-lighten-3",
  borderColor: "error",
};

export const successData = {
  type: "success",
  icon: "mdi-check-circle",
  iconColor: "success",
  borderColor: "success",
};

export const warningData = {
  type: "warning",
  icon: "mdi-alert",
  iconColor: "warning",
  borderColor: "warning",
};

export const infoData = {
  type: "info",
  icon: "mdi-information",
  iconColor: "light-blue-lighten-1",
  borderColor: "light-blue-lighten-1",
};

export const useWarningStore = defineStore("globalMessage", {
  state: () => ({
    alerts: reactive([]),
  }),

  actions: {
    showAlert(data) {
      const id = alertId++;
      this.alerts.push({
        id,
        ...data,
        visible: true,
      });
      return id;
    },

    hideAlert(id) {
      const index = this.alerts.findIndex((alert) => alert.id === id);
      if (index !== -1) {
        this.alerts.splice(index, 1);
      }
    },

    showGenericErrorMessage(messageText, timeout = false) {
      const data = {
        ...errorData,
        message: messageText,
        timeout,
      };
      this.showAlert(data);
    },

    showGenericSuccessMessage(messageText, timeout = true) {
      const data = {
        ...successData,
        message: messageText,
        timeout,
      };
      this.showAlert(data);
    },

    showGenericWarningMessage(messageText, timeout = false) {
      const data = {
        ...warningData,
        message: messageText,
        timeout,
      };
      this.showAlert(data);
    },

    showGenericInfoMessage(messageText, timeout = false) {
      const data = {
        ...infoData,
        message: messageText,
        timeout,
      };
      return this.showAlert(data);
    },
  },
});
