import { useMasters } from "~/store/masters";
import { useCalendar } from "~/store/calendar";
import { getTechniciansData, getDefaultWorkTime } from "~/composables/crews";

import { useGlobalScheduling } from "~/store/globalScheduling";

// por tipos
export const woTypesColors = {
  CORRDFR: "grey-lighten-2", //Correctivo programado
  CORRINM: "deep-orange-lighten-3", //Correctivo inmediato
  DISCORR: "teal-accent-3", //correctivo tecnologicas(modificaciones)
  DISMEJ: "green-lighten-2", //mejoras tecnologicas (modificaciones)
  DISENS: "teal-lighten-3", //Maniobras
  INSPNT: "yellow-accent-3", //inspecciones específicas
  INSPRD: "lime-accent-1", //inspecciones periodicas
  MNBAMT: "light-green-lighten-1", // Maniobra Alta/ Media Tension
  OTRMAN: "lime-lighten-1", //Otras manipulaciones
  PRVPRD: "pink-lighten-4", // Predictivo
  PRVSIS: "amber-lighten-4", //Preventivo sistematica
  DEFAULT: "cyan-lighten-5", //Defecto
  ALERT: "deep-orange-darken-4", // Duración <= 1
};

/**
 * Función que adapta la salida de las OTs a estructura de cuadrillas/OTs para ser pintadas en el calendario
 * @param {Array} wos_data
 * @returns {Array} crews_data
 */
export const parseAlgorithmWOToCrews = (wos_data) => {
  const masters = useMasters();
  const globalScheduling = useGlobalScheduling();
  const crews_data = [];

  wos_data.forEach((ot, index) => {
    let crew = crews_data.find((crew_data) => crew_data.id === ot.OPT_CREW);
    const ot_wonumId = ot.COD_WONUM_ID.split("-")[1];

    if (
      globalScheduling.algorithmLastExecution !== null ||
      new Date(globalScheduling.algorithmLastExecution) <
        new Date(ot.OPT_EXECUTION_DATE)
    ) {
      globalScheduling.algorithmLastExecution = ot.OPT_EXECUTION_DATE;
    }

    if (!crew) {
      const crews = masters.getAllCrews;
      const crewInfo = crews.find((c) => c.AMCREWID == ot.OPT_CREW);
      crew = {
        id: ot.OPT_CREW,
        name: crewInfo?.AMCREW ?? "",
        disabled: [],
        showCrewData: false,
        ots: [],
        description: crewInfo?.DESCRIPTION ?? "",
        hours: crewInfo?.HOURS ?? "",
        person_num: ot?.OPT_NWORKERS ?? 0,
        type: crewInfo?.AMCREWTYPE ?? "",
        real: true,
        sites: getCrewSitesNames(crewInfo),
        slots: Array.from({ length: 7 }, () => {
          return {
            AMCREWAVAILAEID: "",
            LABORCODE_AMCREWLABORAEID: "",
            OPDATE: "",
            AMCREWID: "",
            available: false,
            workers: [],
          };
        }),
        workers: Array.from({ length: 7 }, () => []), //Inicializo un array de arrays para los por semana
      };
      crews_data.push(crew);
    }

    const existingOT = crew.ots.find(
      (wo) => wo.i === ot_wonumId && ot.subcontractor === ot.SUBCONTRACTOR
    );

    const day_index = getDateDay(new Date(ot.OPT_SCHEDSTART));
    crew.slots[day_index].available = true;

    if (!existingOT) {
      crew.ots.push({
        actualSchedStart: new Date(ot.OPT_SCHEDSTART),
        color: woTypesColors[ot.COD_WORKTYPE] ?? woTypesColors["DEFAULT"],
        crewId: ot.OPT_CREW,
        description: ot.DES_DESCRIPTION,
        disabled: [0, 6],
        duration: String(ot.MET_ESTDUR),
        durationText: parseHoursToText(parseFloat(ot.MET_ESTDUR)),
        enabledDays: [getDateDay(new Date(ot.OPT_SCHEDSTART))],
        end: new Date(ot.OPT_SCHEDSTART),
        endsAfterWeek: isAfterWeek(ot.OPT_OT_ENDDATE, ot.OPT_SCHEDSTART),
        h: 1,
        i: ot_wonumId,
        isDragging: false,
        location: masters.getLocationCodeAndName(ot.COD_LOCATION_ID),
        locationId: ot.COD_LOCATION_ID,
        locationName: masters.getLocationName(ot.COD_LOCATION_ID),
        ot: ot_wonumId,
        regular: ot.FLG_NOTREGULAR,
        schedStart: new Date(ot.OPT_SCHEDSTART),
        start: new Date(ot.OPT_SCHEDSTART),
        startsBeforeWeek: isBeforeWeek(ot.OPT_OT_STARTDATE, ot.OPT_SCHEDSTART),
        subcontractor: ot.SUBCONTRACTOR,
        timeWorked: "0",
        w: 1,
        x: getDateDay(new Date(ot.OPT_SCHEDSTART)),
        y: crew.ots.length,
      });
    } else {
      existingOT.enabledDays.push(getDateDay(new Date(ot.OPT_SCHEDSTART)));
      const start_day = Math.min(...existingOT.enabledDays);
      const end_day = Math.max(...existingOT.enabledDays);
      existingOT.w = Math.max(end_day - start_day, 1) + 1;
      existingOT.x = start_day;
      //   Si la ot que se itera es la misma pero comienza antes
      if (new Date(ot.OPT_SCHEDSTART) < existingOT.schedStart) {
        existingOT.actualSchedStart = new Date(ot.OPT_SCHEDSTART);
        existingOT.start = new Date(ot.OPT_SCHEDSTART);
        existingOT.schedStart = new Date(ot.OPT_SCHEDSTART);
      } else {
        existingOT.end = new Date(ot.OPT_SCHEDSTART);
      }
    }

    let workers_crew_index = getDateDay(new Date(ot.OPT_SCHEDSTART));

    const workers = (
      ot.OPT_TRABAJADORES?.slice(1, -1).replace(/NULL/g, "") || ""
    )
      .split(",")
      .map((worker) => worker.trim())
      .filter((worker) => worker !== "");
    crew.slots[workers_crew_index].workers = [
      ...new Set(crew.slots[workers_crew_index].workers.concat(workers)),
    ];
  });

  return sortElements(crews_data, "description");
};

export const parseCalendarWOToCrews = (ots) => {
  const masters = useMasters();
  const globalScheduling = useGlobalScheduling();
  const nuxtApp = useNuxtApp();

  let crews_with_wos = [];
  let wos_without_crews = [];
  let crews_without_wos = [];

  masters.getAllCrews.forEach((crew, index) => {
    const crew_data = {
      id: crew.AMCREW,
      name: crew.AMCREW,
      disabled: [],
      showCrewData: false,
      ots: [],
      unplanned_ots: [],
      description: crew.DESCRIPTION || "",
      hours: crew.HOURS || "",
      person_num: crew.PERSONNUM || 0,
      type: crew.AMCREWTYPE || "",
      orgid: crew.ORGID || "TEST",
      real: true,
      sites: getCrewSitesNames(crew),
      slots: Array.from({ length: 7 }, () => {
        return {
          AMCREWAVAILAEID: "",
          LABORCODE_AMCREWLABORAEID: "",
          OPDATE: "",
          AMCREWID: "",
          available: false,
          workers: [],
        };
      }), //Inicia array de valor false para indicar si hay slot (cuadrilla asociada en el día)
      workers: Array.from({ length: 7 }, () => []), //Inicializo un array de arrays para los por semana
    };

    // Slots
    const crew_slots = globalScheduling.crewsSlots[crew.AMCREW];

    if (crew_slots !== undefined && crew_slots.length) {
      crew_slots.forEach((slot) => {
        const day_index = getDateDay(new Date(slot.OPDATE));

        crew_data.slots[day_index].available = true;
        crew_data.slots[day_index].AMCREWAVAILAEID = slot.AMCREWAVAILAEID;
        crew_data.slots[day_index].AMCREWID = slot.AMCREWID;
        crew_data.slots[day_index].OPDATE = slot.OPDATE;
        crew_data.slots[day_index].LABORCODE_AMCREWLABORAEID =
          slot.LABORCODE_AMCREWLABORAEID;

        if (slot.LABORCODE_AMCREWLABORAEID !== null) {
          const workers = slot.LABORCODE_AMCREWLABORAEID.split(",").map(
            (worker) => worker.replace(/_.*/, "")
          );
          crew_data.slots[day_index].workers = workers;
          crew_data.workers[day_index] = workers;
        }
      });
    }

    // OTs
    const crew_workorders = ots.filter((wo) => {
      return wo.AMCREW === crew.AMCREW;
    });

    const { wo, unplanned_wo } = parsePlanningOTs(crew_workorders, crew_data);

    crew_data.ots = wo;
    crew_data.unplanned_ots = unplanned_wo;

    if (crew_data.ots.length || crew_data.unplanned_ots.length) {
      crews_with_wos.push(crew_data);
    } else {
      crews_without_wos.push(crew_data);
    }
  });

  crews_with_wos = sortElements(crews_with_wos, "description");
  crews_without_wos = sortElements(crews_without_wos, "description");

  const workorders_null = ots.filter((wo) => {
    return wo.AMCREW === null;
  });

  if (workorders_null.length) {
    const not_crew_data = {
      id: nuxtApp.$i18n.t("scheduling.withoutCrewId"),
      name: nuxtApp.$i18n.t("scheduling.withoutCrew"),
      description: nuxtApp.$i18n.t("scheduling.withoutCrew"),
      disabled: [],
      showCrewData: false,
      ots: [],
      unplanned_ots: [],
      hours: DEFAULT_CREW_HOURS,
      person_num: DEFAULT_CREW_WORKERS,
      type: "",
      orgid: "",
      real: false,
      slots: createWithoutCrewSlots(), //No hay slots porque son OTs sin cuadrila
      workers: Array.from({ length: 7 }, () => []), //Inicializo un array de arrays para los por semana
    };
    const { wo, unplanned_wo } = parsePlanningOTs(
      workorders_null,
      not_crew_data
    );
    not_crew_data.ots = wo;
    not_crew_data.unplanned_ots = unplanned_wo;
    wos_without_crews.push(not_crew_data);
  }

  // Si hay filtros aplicados, filtramos las cuadrillas si hay filtros aplicados
  const filtered = countAppliedFilters(globalScheduling.filters) > 0;

  const wosWithoutCrewsFiltered = filtered
    ? wos_without_crews.filter(hasOtsOrUnplannedOts)
    : wos_without_crews;
  const crewsWithWosFiltered = filtered
    ? crews_with_wos.filter(hasOtsOrUnplannedOts)
    : crews_with_wos;
  const crewsWithoutWosFiltered = filtered
    ? crews_without_wos.filter(hasOtsOrUnplannedOts)
    : crews_without_wos;
  return [
    ...wosWithoutCrewsFiltered,
    ...crewsWithWosFiltered,
    ...crewsWithoutWosFiltered,
  ];
};

const createWithoutCrewSlots = () => {
  const calendar = useCalendar();
  let slots = [];

  for (let i = 0; i < 7; i++) {
    const date_day = addDays(calendar.getOnlyWeekDays[0], i);
    const holiday = i >= 5;

    slots.push({
      available: !holiday,
      AMCREWAVAILAEID: "",
      AMCREWID: "",
      OPDATE: formatDate(date_day, "iso"),
      LABORCODE_AMCREWLABORAEID: "",
    });
  }

  return slots;
};

// Función para verificar si un elemento tiene ots o unplanned_ots con valores
const hasOtsOrUnplannedOts = (element) => {
  return element.ots.length || element.unplanned_ots.length;
};

export const parsePlanningOTs = (ots, crew) => {
  if (ots.length === 0) {
    return { wo: [], unplanned_wo: [] };
  }

  const globalScheduling = useGlobalScheduling();

  let positionY = 0;
  const crew_workorders = [];
  const crew_unplanned_wo = [];

  const sorted_ots = ots.sort(
    (a, b) => new Date(a.FECHA_PROGRAMADA) - new Date(b.FECHA_PROGRAMADA)
  );

  sorted_ots.forEach((ot) => {
    positionY = 0;

    const start_date = generateStartDate(
      ot.AMCREW,
      ot.FECHA_PROGRAMADA,
      globalScheduling.crewsSlots
    );

    if (start_date == null) {
      crew_unplanned_wo.push(createWOWihtoutSlot(ot));
      return;
    }

    crew_workorders.push(createPlannedWO(ot, crew, start_date, positionY));

    positionY += 1;
  });

  return { wo: crew_workorders, unplanned_wo: crew_unplanned_wo };
};

/*   Buscar si la cuadrilla asociada a la OT tiene "slot" para ese día de fecha_programada	
	Sí SÍ que tiene slot -> la OT empieza a trabajarse ese día
	Si NO tiene slot -> La OT empieza a trabajarse en el siguiente día con slot de la cuadrilla */
const generateStartDate = (AMCREW, scheduledDate, crews) => {
  let start_date =
    compareDateWithToday(new Date(scheduledDate), "<") &&
    isCurrentWeek(scheduledDate)
      ? formatDate(new Date(), "iso")
      : scheduledDate;

  const crew_id = AMCREW ?? CREW_NULL;

  //Si no hay Slots de la cuadrilla se devuelve null para mostrar como unplanned_ot
  if (!crews[crew_id]) {
    return null;
  }

  let date = null;

  crews[crew_id].forEach((slot) => {
    if (
      slot.OPDATE == start_date
      // && slot.LABORCODE_AMCREWLABORAEID
    ) {
      date = start_date;
    }

    if (
      date == null &&
      new Date(slot.OPDATE) > new Date(start_date)
      // &&
      // slot.LABORCODE_AMCREWLABORAEID
    ) {
      date = slot.OPDATE;
    }
  });
  return date;
};

const generateEndDate = (AMCREW, startDate, duration, crews, crewInfo) => {
  const calendar = useCalendar();
  const masters = useMasters();

  let dias = 0;

  let horasRestantes = duration;
  let endDate = "";
  const enabledDays = [];
  const workedRemainingHours = {};
  const workedHours = {};

  if (duration <= 0) {
    enabledDays.push(getDateDay(new Date(startDate)));
  }

  const amcrew_id = AMCREW ?? CREW_NULL;

  if (!crews[amcrew_id]) {
    const horasDisponibles = getDefaultWorkTime();

    while (horasRestantes > 0) {
      const daySum = addDays(new Date(startDate), dias);
      const day_week = getDateDay(daySum);
      const holiday = [5, 6].includes(day_week);

      if (holiday) {
        break;
      }

      if (horasRestantes > horasDisponibles) {
        dias++;
      }
      horasRestantes -= horasDisponibles;

      if (daySum <= calendar.currentWeekEndDate) {
        enabledDays.push(getDateDay(daySum));
        workedRemainingHours[getDateDay(daySum)] = horasRestantes;
        workedHours[getDateDay(daySum)] = horasDisponibles;
      }
    }
  } else {
    crews[amcrew_id].forEach((slot) => {
      // Obtiene la info original de la cuadrilla

      const crew_data = masters.getAllCrewsAMCREW[amcrew_id];

      // Los trabajadores disponibles en el slot. Se comprueba si son de OTs sin cuadrilla para asignar el valor por defecto
      const assignedWorkers =
        amcrew_id != CREW_NULL
          ? parseInt(crew_data.PERSONNUM)
          : DEFAULT_CREW_WORKERS;
      const work_hours =
        amcrew_id != CREW_NULL
          ? parseFloat(crewInfo.hours)
          : DEFAULT_CREW_HOURS;

      // Si hay trabajadores en el slot y la fecha del slot es igual o posterior a la del slot y quedan horas pendiente de completar
      if (
        assignedWorkers != 0 &&
        new Date(slot.OPDATE) >= new Date(startDate) &&
        horasRestantes > 0
      ) {
        // Si los días es menor que lo que queda de semana
        if (
          getDateDay(new Date(slot.OPDATE)) + dias <=
            getDateDay(calendar.currentWeekEndDate) &&
          assignedWorkers
        ) {
          // Se añade el día del slot como habilitad
          enabledDays.push(getDateDay(new Date(slot.OPDATE)));

          // Se calculan las horas de la cuadrilla por el número de técnicos del slot
          let horasDisponibles = work_hours * assignedWorkers;

          // A las horas restantes se les restan las horas trabajadas del slot
          horasRestantes -= horasDisponibles;

          // Se añden las horas pendientes de trabajar y las horas trabajables a la cuadrilla
          workedRemainingHours[getDateDay(new Date(slot.OPDATE))] =
            horasRestantes;
          workedHours[getDateDay(new Date(slot.OPDATE))] = horasDisponibles;
        }
      }
    });
  }

  // Si sobran horas significa que todavía me faltan días de trabajo pongo que termina la prox semana
  if (horasRestantes > 0) {
    endDate = getDateYMD(addDays(new Date(calendar.currentWeekEndDate), 1));
  } else {
    const minDay =
      Math.min(...enabledDays) !== Infinity ? Math.min(...enabledDays) : 0;
    const maxDay =
      Math.max(...enabledDays) !== -Infinity ? Math.max(...enabledDays) : 0;
    const days = maxDay - minDay;
    endDate = getDateYMD(addDays(new Date(startDate), days));
  }

  return {
    end: endDate,
    enabledDays,
    workedRemainingHours,
    workedHours,
  };
};

//BUG: Esto debería estar en Store/globalScheduling
export const cleanAllSchedulingFilters = (filter_scheduling = true) => {
  const globalScheduling = useGlobalScheduling();
  // comunes
  globalScheduling.filters.wo_number = "";
  globalScheduling.filters.wo_description = "";
  globalScheduling.filters.worktype = [];
  globalScheduling.filters.crew = [];
  globalScheduling.filters.group = [];
  globalScheduling.filters.site = [];
  globalScheduling.filters.asset = [];
  globalScheduling.filters.location = [];
  globalScheduling.filters.subcontractor = [];
  globalScheduling.filters.technicians = [];
  globalScheduling.filters.gama = [];
  globalScheduling.filters.regular = "";
  // Planificado
  globalScheduling.filters.wo_mother_number = "";
  globalScheduling.filters.technology = [];
  globalScheduling.filters.location_type = [];
  globalScheduling.filters.reprogrammable = false;
  globalScheduling.filters.qse = false;

  if (filter_scheduling) {
    filterScheduling();
  }
};

export const filterScheduling = () => {
  const globalScheduling = useGlobalScheduling();
  globalScheduling.setFilteredPlanningWorkorders();
  globalScheduling.setFilteredAlgorithmWorkorders();
  globalScheduling.setFilteredDelayedPlanningWorkorders();
};

export const getCrewSitesNames = (crew) => {
  if (!crew) return [];

  const masters = useMasters();
  const crew_sites_amcrew = crew.SITEID_AMCREWSITEIDID.split(",");

  const sites = [];

  crew_sites_amcrew.forEach((site_amcrew) => {
    const site = site_amcrew.split("_")[0];
    let site_name = `${site}`;
    if (masters.machines.sites[site]) {
      site_name = site_name + ` - ${masters.machines.sites[site]?.SITEDESC}`;
    }

    sites.push(site_name);
  });

  return sites;
};

export const getRemainingDuration = (totalDuration, timeWorked) => {
  let remaining_duration = parseFloat(totalDuration) - parseFloat(timeWorked);

  if (remaining_duration <= 0) {
    return null;
  }

  return remaining_duration;
};

// Función para crear órdenes no planificadas
const createWOWihtoutSlot = (ot) => {
  const nuxtApp = useNuxtApp();
  const masters = useMasters();

  const start_date =
    new Date(ot.FECHA_PROGRAMADA) < new Date()
      ? formatDate(new Date(), "iso")
      : ot.FECHA_PROGRAMADA;

  return {
    actualSchedStart: new Date(ot.FECHA_PROGRAMADA),
    claseTrabajo: ot.CLASE_TRABAJO,
    color: woTypesColors[ot.CLASE_TRABAJO] ?? woTypesColors["DEFAULT"],
    crewId: ot.AMCREW ?? nuxtApp.$i18n.t("scheduling.withoutCrew"),
    description: ot.DESCRIPTION,
    disabled: [],
    duration: ot.DURACION,
    durationText: parseHoursToText(parseFloat(ot.DURACION)),
    enabledDays: [],
    end: start_date,
    endsAfterWeek: false,
    entrega: ot.ENTREGA,
    h: 0,
    i: ot.WONUM,
    isDragging: false,
    location: masters.getLocationCodeAndName(ot.SITEID + "-" + ot.LOCATION),
    locationId: ot.LOCATION,
    locationName: masters.getLocationName(ot.SITEID + "-" + ot.LOCATION),
    mother_wo_description: ot.DESCOTMADRE,
    mother_workorderid: ot.OTMADRE,
    orgid: ot.ORGID,
    ot: ot.WONUM,
    personGroup: ot.PERSONGROUP,
    regular: ot.FLG_NOTREGULAR,
    schedStart: new Date(start_date),
    siteid: ot.SITEID,
    start: new Date(start_date),
    startsBeforeWeek: false,
    subcontractor: "",
    technicians: ot.PLANLABOR ? ot.PLANLABOR.split("#") : [],
    timeWorked: ot.ACTLABHRS,
    w: 0,
    workedHours: null,
    workedRemainingHours: null,
    workorderid: ot.WORKORDERID,
    x: 0,
    y: 0,
  };
};

// Función para crear órdenes planificadas
const createPlannedWO = (ot, crew, start_date, positionY) => {
  const nuxtApp = useNuxtApp();
  const masters = useMasters();
  const globalScheduling = useGlobalScheduling();

  const start = start_date;

  const { end, enabledDays, workedRemainingHours, workedHours } =
    generateEndDate(
      ot.AMCREW,
      start_date,
      ot.DURACION - ot.ACTLABHRS,
      globalScheduling.crewsSlots,
      crew
    );

  const start_day = Math.min(...enabledDays);
  const end_day = Math.max(...enabledDays);
  const endTmp = new Date(end).setHours(23, 59, 59, 999);
  const size = end_day == start_day ? 1 : Math.max(end_day - start_day, 1) + 1;

  return {
    actualSchedStart: new Date(ot.FECHA_PROGRAMADA),
    claseTrabajo: ot.CLASE_TRABAJO,
    color: woTypesColors[ot.CLASE_TRABAJO] ?? woTypesColors["DEFAULT"],
    crewId: ot.AMCREW ?? nuxtApp.$i18n.t("scheduling.withoutCrew"),
    description: ot.DESCRIPTION,
    disabled: [0, 6],
    duration: ot.DURACION,
    durationText: parseHoursToText(parseFloat(ot.DURACION)),
    enabledDays: [...enabledDays],
    end: new Date(endTmp),
    endsAfterWeek: isAfterWeek(end, start),
    entrega: ot.ENTREGA,
    h: 1,
    i: ot.WONUM,
    isDragging: false,
    location: masters.getLocationCodeAndName(ot.SITEID + "-" + ot.LOCATION),
    locationId: ot.LOCATION,
    locationName: masters.getLocationName(ot.SITEID + "-" + ot.LOCATION),
    mother_wo_description: ot.DESCOTMADRE,
    mother_workorderid: ot.OTMADRE,
    orgid: ot.ORGID,
    ot: ot.WONUM,
    personGroup: ot.PERSONGROUP,
    regular: ot.FLG_NOTREGULAR,
    schedStart: new Date(start),
    siteid: ot.SITEID,
    start: new Date(start),
    startsBeforeWeek: isBeforeWeek(start, start),
    subcontractor: "",
    technicians: ot.PLANLABOR ? ot.PLANLABOR.split("#") : [],
    timeWorked: ot.ACTLABHRS,
    w: size,
    workedHours,
    workedRemainingHours,
    workorderid: ot.WORKORDERID,
    x: getDateDay(new Date(start)),
    y: positionY,
  };
};

const isBeforeWeek = (start, schedStart) => {
  return (
    new Date(start) < new Date(schedStart) &&
    getWeek(new Date(start)) !== getWeek(new Date(schedStart))
  );
};

const isAfterWeek = (end, schedStart) => {
  return (
    new Date(end) > new Date(schedStart) &&
    getWeek(new Date(end)) !== getWeek(new Date(schedStart))
  );
};
