import validate from "/opt/app-root/src/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/opt/app-root/src/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/app-root/src/middleware/auth.js"),
  "check-admin": () => import("/opt/app-root/src/middleware/check-admin.js"),
  "check-language": () => import("/opt/app-root/src/middleware/check-language.js"),
  "check-technician": () => import("/opt/app-root/src/middleware/check-technician.js")
}