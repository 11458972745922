// masters.js
import { defineStore } from "pinia";
import WWSS from "~/utils/wwss";
import { useWarningStore } from "./globalMessage";
import { useCalendar } from "~/store/calendar";
import { useUserStore } from "~/store/auth";
import { useLocationSelector } from "~/store/locationSelector";

import { getWeek, changeFormatMDY } from "~/composables/dates";
import { beforeUnloadHandler } from "~/composables/general";
import { woTypesColors } from "~/composables/workorders";

export const useMasters = defineStore("masters", {
  state: () => ({
    globalLoading: true,
    machines: {
      changed_at: null,
      list: [],
      totalList: [],
      sites: {},
      assets: {},
      locations: {
        list: {}, //Filtrado
        map: {}, //Peticiones
      },
      countries: {},
      orgids: {},
      parqueswer: {},
    },
    holidays: {
      changed_at: null,
      list: [],
      holidaysPerWeek: {},
    },
    mates: {
      changed_at: null,
      list: [],
    },
    crews: {
      changed_at: null,
      list: [],
      map: {},
      mapId: {},
      mapAMCREW: {},
      types: [],
      types_changed_at: null,
    },
    subcontractors: {
      list: [],
      changed_at: null,
    },
    personGroups: {
      list: [],
      changed_at: null,
    },
    workTypes: {
      list: [],
      map: {},
      changed_at: null,
    },
    locTypes: {
      list: [],
      changed_at: null,
    },
    technologies: {
      list: [],
      changed_at: null,
    },
  }),
  actions: {
    // areMachinesUpdated(){
    //     if(this.machines.changed_at === null){
    //         return false;
    //     }
    //     const timeDifference = new Date().getTime() - this.machines.changed_at;
    //     return timeDifference < 120000 // 3 horas en milisegundos 3 * 60 * 60 * 1000
    // },
    setMachines(data) {
      this.machines.changed_at = new Date().getTime();
      this.machines.totalList = data;
      const machines_data = JSON.parse(JSON.stringify(data));
      let machines = {
        list: [],
        totalList: [],
        sites: {},
        assets: {},
        locations: {
          list: {}, //Filtrado
          map: {}, //Peticiones
        },
        countries: {},
        orgids: {},
        parqueswer: {},
      };

      machines.list = machines_data.filter((machine) => {
        return machine.VISUALIZA == "1";
      });

      for (const machine of machines.list) {
        // Sites
        machines.sites[machine.SITEID] = {
          DESLOCATION: machine.DESLOCATION,
          SITEDESC: machine.SITEDESC,
          ORGID: machine.ORGID,
        };

        // Countries
        if (!machines.countries[machine.COUNTRY]) {
          machines.countries[machine.COUNTRY] = {
            COUNTRYDESC_ES: machine.COUNTRYDESC_ES,
            COUNTRYDESC_EN: machine.COUNTRYDESC_EN,
            sites: [],
          };
        }

        // Añadimos el SITEID al país si no está añadido
        if (
          !machines.countries[machine.COUNTRY].sites.includes(machine.SITEID)
        ) {
          machines.countries[machine.COUNTRY].sites.push(machine.SITEID);
        }

        // ORGIDs
        if (!machines.orgids[machine.ORGID]) {
          machines.orgids[machine.ORGID] = {
            ORGDESC_ES: ORGANIZATIONS[machine.ORGID]?.ORGDESC_ES ?? "OTROS",
            ORGDESC_EN: ORGANIZATIONS[machine.ORGID]?.ORGDESC_EN ?? "OTHERS",
            countries: [machine.COUNTRY],
            sites: [],
          };
        }

        // Añadimos el país al ORGID si no está añadido
        if (
          !machines.orgids[machine.ORGID].countries.includes(machine.COUNTRY)
        ) {
          machines.orgids[machine.ORGID].countries.push(machine.COUNTRY);
        }

        // Añadimos el SITEID al ORGID si no está añadido
        if (!machines.orgids[machine.ORGID].sites.includes(machine.SITEID)) {
          machines.orgids[machine.ORGID].sites.push(machine.SITEID);
        }

        // Locations
        let location = `${machine.SITEID}-${machine.LOCATION}`;

        if (!machines.locations.map[location]) {
          machines.locations.map[location] = {
            DESLOCATION: machine.DESLOCATION, //input
            DESWINDFARM: machine.DESWINDFARM, //output del algoritmo
          };
        }

        if (!machines.locations.list[machine.LOCATION]) {
          machines.locations.list[machine.LOCATION] = {
            DESLOCATION: machine.DESLOCATION, //input
          };
        }

        // Parquewers
        if (!machines.parqueswer[machine.PARQUEWER]) {
          const parquewer = `${machine.ORGID}-${machine.SITEID}-${
            machine.LOCATION.split(".")[0]
          }`;
          machines.parqueswer[machine.PARQUEWER] = parquewer;
        }

        // Assets
        const asset = machine.LOCATION.split(".")[0];
        if (!machines.assets[asset]) {
          machines.assets[asset] = machine.DESWINDFARM;
        }
      }
      this.machines.list = machines.list;
      this.machines.sites = machines.sites;
      this.machines.assets = machines.assets;
      this.machines.countries = machines.countries;
      this.machines.locations = machines.locations;
      this.machines.orgids = machines.orgids;
      this.machines.parqueswer = machines.parqueswer;
      this.machines.changed_at = new Date().getTime();
    },
    setHolidays(data) {
      this.holidays.changed_at = new Date().getTime();
      this.holidays.list = data;
    },
    setHolidaysPerWeek(data) {
      // const nuxtApp = useNuxtApp();
      // const locale = nuxtApp?.$i18n?.locale?.value?.toUpperCase() ?? "ES";

      const listSiteId = Object.keys(this.machines.sites);
      const listOrgIds = Object.keys(this.machines.orgids);
      const listCountryId = Object.keys(this.machines.countries);
      for (const element of data) {
        if (
          listSiteId.includes(element.CALENDARIO) ||
          (listCountryId.includes(element.CALENDARIO) &&
            listOrgIds.includes(element.ORGID))
        ) {
          const week = getWeek(new Date(changeFormatMDY(element.FESTIVO)));
          this.holidays.holidaysPerWeek[week] ??= [];
          this.holidays.holidaysPerWeek[week].push(element);
        }
      }

      const calendar = useCalendar();
      calendar.setHolidaysWeek();
    },
    setMates(data) {
      this.mates.changed_at = new Date().getTime();
      const mates = data.map((mate) => {
        return {
          ...mate,
          SEARCH: `${mate.LABORID} ${
            mate.DEFAULTPERSONGROUP ? " - " + mate.DEFAULTPERSONGROUP : ""
          } - ${mate.FIRSTNAME} ${mate?.LASTNAME || ""}`,
        };
      });

      this.mates.list = mates;
    },
    setCrews(data) {
      this.crews.changed_at = new Date().getTime();

      data = sortElements(data, "AMCREW");

      let modifiedData = data.map((item) => {
        const siteIds = item.SITEID_AMCREWSITEIDID.split(",").map(
          (id) => id.split("_")[0]
        );
        item.SITEID = Array.from(new Set(siteIds));
        return item; // Retorna el objeto modificado
      });

      this.crews.mapId = modifiedData.reduce((obj, item) => {
        obj[item.AMCREWID] = item;
        return obj;
      }, {});

      this.crews.mapAMCREW = modifiedData.reduce((obj, item) => {
        obj[item.AMCREW] = item;
        return obj;
      }, {});

      this.crews.list = modifiedData;
    },
    setCrewTypes(data) {
      this.crews.types = sortElements(data, "AMCREWTYPE");
      this.crews.types_changed_at = new Date().getTime();
    },
    setSubcontractors(data) {
      this.subcontractors.list = sortElements(data, "VALUE");
      this.subcontractors.changed_at = new Date().getTime();
    },
    setPersonGroups(data) {
      this.personGroups.list = sortElements(data, "PERSONGROUP");
      this.personGroups.changed_at = new Date().getTime();
    },
    setWorkTypes(data) {
      const workTypes_list = [];
      const workTypes = {};
      for (let i = 0; i < data.length; i++) {
        const { WORKTYPE, DESCRIPTION_EN, DESCRIPTION_ES, ORGID } = data[i];

        if (!workTypes[WORKTYPE]) {
          workTypes_list.push(data[i]);
          workTypes[WORKTYPE] = {
            WORKTYPE,
            DESCRIPTION_EN,
            DESCRIPTION_ES,
            ORGIDs: [],
            COLOR: woTypesColors[WORKTYPE] || woTypesColors["DEFAULT"], // Color por defecto si no se encuentra en woTypesColors
          };
        }

        if (!workTypes[WORKTYPE].ORGIDs.includes(ORGID)) {
          workTypes[WORKTYPE].ORGIDs.push(ORGID);
        }
      }

      this.workTypes.list = sortElements(workTypes_list, "WORKTYPE");
      this.workTypes.map = workTypes;
      this.workTypes.changed_at = new Date().getTime();
    },

    setLocTypes(data) {
      this.locTypes.list = sortElements(
        removeDuplicates(data, "VALUE"),
        "VALUE"
      );
      this.locTypes.changed_at = new Date().getTime();
    },

    setTechnologies(data) {
      this.technologies.list = sortElements(data, "VALUE");
      this.technologies.changed_at = new Date().getTime();
    },
    deleteCrew(crewId) {
      const filtered_crews = this.crews.list.filter(
        (crew) => crew.AMCREWID !== crewId
      );
      this.setCrews(filtered_crews);
    },
    addCrew(data) {
      this.crews.list.push(data);
      this.setCrews(this.crews.list);
    },
    async getAllMachinesWS() {
      const globalMessage = useWarningStore();
      const userStore = useUserStore();
      const nuxtApp = useNuxtApp();
      const locationSelector = useLocationSelector();

      const data = await fetchPostData(WWSS.user.machines.list, {
        item: userStore.login?.CodOperarioMaximo,
      }).catch((reason) => {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.machines")
        );
      });

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.machines")
        );
        return [];
      } else {
        this.setMachines(data);
        return this.machines.list;
      }
    },
    async getHolidaysWS(params = { country: "", siteid: "" }) {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.holidays, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.holidays")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.holidays")
        );
        return [];
      } else {
        this.setHolidays(data);
        this.setHolidaysPerWeek(data);
        return this.holidays.list;
      }
    },
    async getAllMatesWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();
      const userStore = useUserStore();

      const data = await fetchPostData(WWSS.user.mates, {
        item: userStore?.login?.CodOperarioMaximo,
      }).catch((reason) => {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.mates")
        );
      });

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.mates")
        );
        return [];
      } else {
        this.setMates(data);
        return this.mates.list;
      }
    },
    async getAllCrewsWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();
      const userStore = useUserStore();

      if (!this.machines.sites || this.machines.sites.length) {
        globalMessage.showGenericWarningMessage(
          nuxtApp.$i18n.t("errors.messages.backend.noSites")
        );
        return [];
      }
      const listSitesId = Object.keys(this.machines.sites);

      let parameters = {
        siteid: listSitesId.toString(),
      };

      if (hasRole("regularSubcontractor")) {
        parameters.subcontrata = userStore.getSubcontractor;
      }

      const data = await fetchPostData(
        WWSS.user.crews.get_all_crews_siteid,
        parameters
      ).catch((reason) => {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.crews")
        );
      });

      if ((data && data.error) || (data && data.code)) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.crews")
        );
        return [];
      } else {
        this.setCrews(data);
        return this.crews.list;
      }
    },
    setGlobalLoading(value) {
      this.globalLoading = value;
    },
    async getAllSubcontractorsWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.subcontractors, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.subcontractors")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.subcontractors")
        );
        return [];
      } else {
        this.setSubcontractors(sortElements(data, "DESCRIPTION_ES"));
        return this.subcontractors.list;
      }
    },
    async getAllCrewTypesWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.crews.types, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.crewTypes")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.crewTypes")
        );
        return [];
      } else {
        this.setCrewTypes(sortElements(data, "DESCRIPTION"));
        return this.crews.types;
      }
    },
    async getAllPersonGroupsWS() {
      const globalMessage = useWarningStore();
      const userStore = useUserStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.personGroups, {
        item: userStore?.login?.CodOperarioMaximo,
      }).catch((reason) => {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.perosnGroups")
        );
      });

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.personGroups")
        );
        return [];
      } else {
        this.setPersonGroups(data);
        return this.personGroups.list;
      }
    },
    async getAllWorkTypesWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.workTypes, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.workTypes")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.workTypes")
        );
        return [];
      } else {
        this.setWorkTypes(data);
        return this.workTypes.list;
      }
    },
    async getAllLocTypesWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.locTypes, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.locTypes")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.locTypes")
        );
        return [];
      } else {
        this.setLocTypes(data);
        return this.locTypes.list;
      }
    },
    async getAllTechnologiesWS() {
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();

      const data = await fetchPostData(WWSS.master.technologies, {}).catch(
        (reason) => {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("errors.messages.backend.technologies")
          );
        }
      );

      if (data && data.error) {
        globalMessage.showGenericErrorMessage(
          nuxtApp.$i18n.t("errors.messages.backend.technologies")
        );
        return [];
      } else {
        this.setTechnologies(data);
        return this.technologies.list;
      }
    },
    setOnCloseConfirm(mode) {
      if (mode) {
        window.addEventListener("beforeunload", beforeUnloadHandler);
      } else {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      }
    },
  },
  getters: {
    getAllMachines: (state) => state.machines.list,
    getMachinesChangedAt: (state) => state.machines.changed_at,
    areMachinesUpdated(state) {
      if (state.machines.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.machines.changed_at;
      return timeDifference < 10800000; // 3 horas en milisegundos 3 * 60 * 60 * 1000
    },
    getSiteName(state) {
      return (siteid) => {
        return state.machines.sites[siteid]?.SITEDESC || "";
      };
    },
    getLocationCodeAndName(state) {
      return (locationId) => {
        const location = state.machines.locations.map[locationId];
        const cod_location = locationId.split("-")[1] + " - ";
        return cod_location + location?.DESLOCATION || ""; // Retorna DESLOCATION si se encuentra, de lo contrario null
      };
    },
    getLocationName(state) {
      return (locationId) => {
        const location = state.machines.locations.map[locationId];
        return location?.DESLOCATION || locationId; // Retorna DESLOCATION si se encuentra, de lo contrario null
      };
    },
    getHolidays: (state) => state.holidays.list,
    getHolidaysPerWeek: (state) => state.holidays.holidaysPerWeek,
    getHolidaysChangedAt: (state) => state.holidays.changed_at,
    areHolidaysUpdated(state) {
      if (state.holidays.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.holidays.changed_at;
      return timeDifference < 10800000; // 3 horas en milisegundos 3 * 60 * 60 * 1000
    },

    getAllMates: (state) => state.mates.list,
    getMatesChangedAt: (state) => state.mates.changed_at,
    areMatesUpdated(state) {
      if (state.mates.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.mates.changed_at;
      return timeDifference < 10800000; // 3 horas en milisegundos 3 * 60 * 60 * 1000
    },
    areSubcontractorsUpdated(state) {
      if (state.subcontractors.changed_at === null) {
        return false;
      }
      const timeDifference =
        new Date().getTime() - state.subcontractors.changed_at;
      return timeDifference < 10800000;
    },
    getAllSubcontractors: (state) => state.subcontractors.list,
    areCrewTypesUpdated(state) {
      if (state.crews.types_changed_at === null) {
        return false;
      }
      const timeDifference =
        new Date().getTime() - state.crews.types_changed_at;
      return timeDifference < 10800000;
    },
    getAllCrewTypes: (state) => state.crews.types,
    getAllCrews: (state) => state.crews.list,
    getAllCrewsId: (state) => state.crews.mapId,
    getAllCrewsAMCREW: (state) => state.crews.mapAMCREW,
    areCrewsUpdated: (state) => {
      if (state.crews.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.crews.changed_at;
      return timeDifference < 10800000;
    },
    getSites: (state) => {
      return Object.keys(state.machines?.sites)?.join(",") ?? "";
    },
    areWorkTypesUpdated(state) {
      if (state.workTypes.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.workTypes.changed_at;
      return timeDifference < 10800000;
    },
    areLocTypesUpdated(state) {
      if (state.locTypes.changed_at === null) {
        return false;
      }
      const timeDifference = new Date().getTime() - state.locTypes.changed_at;
      return timeDifference < 10800000;
    },
    areTechnologiesUpdated(state) {
      if (state.technologies.changed_at === null) {
        return false;
      }
      const timeDifference =
        new Date().getTime() - state.technologies.changed_at;
      return timeDifference < 10800000;
    },
    arePersonGroupsUpdated(state) {
      if (state.personGroups.changed_at === null) {
        return false;
      }
      const timeDifference =
        new Date().getTime() - state.personGroups.changed_at;
      return timeDifference < 10800000;
    },
  },
});
