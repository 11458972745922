<template>
  <div>
    <client-only>
      <v-app class="app principal__layout">
        <LayoutsHeader />
        <div class="mt-6">
          <div class="container mt-16">
            <CommonEmptyState
              :icon="getErrorIcon"
              :title="getErrorTitle"
              :text="getErrorMessage"
              :redirect="getRedirect"
            >
            </CommonEmptyState>
          </div>
        </div>
      </v-app>
    </client-only>
  </div>
</template>

<script setup >
import { useMasters } from "~/store/masters";

const nuxtApp = useNuxtApp();
const error = useError();
const masters = useMasters();
const { t } = useI18n();

const handleError = () => {
  clearError({
    redirect: "/",
  });
};

const getErrorIcon = computed(() => {
  const errorIcons = {
    401: "mdi-lock-alert-outline",
    404: "mdi-tab-search",
    500: "mdi-emoticon-sad-outline",
    default: "mdi-tooltip-question-outline",
  };

  const key = error.value.statusCode
    ? errorIcons[error.value.statusCode]
    : errorIcons.default;
  return key;
});

const getErrorTitle = computed(() => {
  const errorTitles = {
    401: "error.401.title",
    500: "error.500.title",
    default: "error.title",
  };

  const key = error.value.statusCode
    ? errorTitles[error.value.statusCode]
    : errorTitles.default;
  return t(key, { page: error.value.statusCode });
});

const getErrorMessage = computed(() => {
  const errorMessages = {
    401: "error.401.text",
    404: "error.404.text",
    default: "error.default.text",
  };

  const key = error.value.statusCode
    ? errorMessages[error.value.statusCode]
    : errorMessages.default;
  return t(key, { page: error.value.url });
});

const getRedirect = computed(() => {
  let path = "/";
  let button_text = "home.go";

  if (nuxtApp.$i18n.locale.value == "en") path = "/en" + path;

  return {
    link: path,
    to: button_text,
  };
});

onMounted(() => {
  masters.setGlobalLoading(false);
});
</script>
