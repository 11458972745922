// plugins/vuetify.js
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { VTimePicker } from "vuetify/labs/VTimePicker";
import { VNumberInput } from "vuetify/labs/VNumberInput";
import { VTreeview } from "vuetify/labs/VTreeview";

import { en, es } from "vuetify/locale";

const mscTheme = {
  colors: {
    brand: "#fb0201",
    "brand-lighten-1": "#ffc0c0",
    "brand-lighten-3": "#ff5958",
    "brand-darken-7": "#b10403",
    "brand-darken-9": "#500000",
    light: "#e2e8e9",
    "light-lighten-1": "#ffffff",
    "light-lighten-3": "#f9fafb",
    "light-darken-7": "#e1e7f0",
    "light-darken-9": "#dce2ec",
    dark: "#536276",
    "dark-lighten-1": "#d5dae2",
    "dark-lighten-3": "#8797a9",
    "dark-darken-7": "#3e4856",
    "dark-darken-9": "#23272e",
    background: "#FFFFFF",
    error: "#f45826",
    "error-lighten-1": "#FFE3D9",
    "error-lighten-3": "#FF9976",
    "error-darken-7": "#dc4606",
    "error-darken-9": "#af2d03",
    warning: "#f2bc31",
    "warning-lighten-1": "#ffe8b1",
    "warning-lighten-3": "#fedb80",
    "warning-darken-7": "#eaaa06",
    "warning-darken-9": "#c87b06",
    info: "#3c8dec",
    "info-lighten-1": "#ddecfc",
    "info-lighten-3": "#98cdf8",
    "info-darken-7": "#2660cf",
    "info-darken-9": "#234585",
    success: "#63ab40",
    "success-lighten-1": "#e1f1d6",
    "success-lighten-3": "#a0d383",
    "success-darken-7": "#31705b",
    "success-darken-9": "#254a3e",
    primary: "#59a88a",
    "primary-lighten-1": "#d8efe4",
    "primary-lighten-3": "#b2ddca",
    "primary-darken-7": "#458C87",
    "primary-darken-9": "#226762",
  },
  variables: {
    "disabled-opacity": 0.66,
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components: {
      ...components,
      VTimePicker,
      VNumberInput,
      VTreeview,
    },
    directives,
    locale: {
      locale: nuxtApp.$i18n.locale.value,
      fallback: "es",
      messages: { en, es },
    },
    theme: {
      defaultTheme: "mscTheme",
      themes: {
        mscTheme,
      },
    },
    defaults: {
      VButton: {
        class: "text-none",
      },
      VTextField: {
        clearIcon: "mdi-close",
        variant: "outlined",
        density: "compact",
      },
      VTextarea: {
        clearIcon: "mdi-close",
        variant: "outlined",
        density: "compact",
      },
      VSelect: {
        clearIcon: "mdi-close",
        variant: "outlined",
        density: "compact",
      },
      VAutocomplete: {
        clearIcon: "mdi-close",
        variant: "outlined",
        density: "compact",
      },
      VNumberInput: {
        clearIcon: "mdi-close",
        variant: "outlined",
        density: "compact",
        controlVariant: "split",
        inset: true,
      },
      VDataTable: {
        sortAscIcon: "mdi-chevron-up",
        sortDescIcon: "mdi-chevron-down",
        hover: true,
      },
      VListItem: {
        density: "compact",
        slim: true,
      },
      VTab: {
        class: "text-none",
      },
      VSkeletonLoader: {
        boilerplate: true,
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
